import * as React from 'react';
import Seo from '../components/seo';

const fAQPage = () => (
  <div className="container mx-auto py-6 px-4">
    <Seo title="Impressum" description="Impressum" />
    <div className="prose">
      <h1 className="mb-0">Impressum</h1>
      <h2>
        Inhaltlich verantwortlich für diese Seite gemäß §5 Telemediengesetz
        (TMG):
      </h2>
      <p>moments FOTOGRAFIE</p>
      <p>
        Julia & Markus Hofmann
        <br />
        Kapellenstr. 8
        <br />
        79292 Pfaffenweiler
      </p>

      <p>
        Email: mail@moments-fotografie.com
        <br />
        Webseite:{' '}
        <a href="https://moments-fotografie.com" target="_blank">
          moments-fotografie.com
        </a>
      </p>
    </div>
  </div>
);

export default fAQPage;
